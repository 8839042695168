import { NetworkEnum } from "interfaces/sharedEnums"

export const currencyNameByNetwork = {
  [NetworkEnum.POLYGON_MAINNET]: "Matic",
  [NetworkEnum.POLYGON_AMOY]: "Matic",
  [NetworkEnum.POLYGON_MUMBAI]: "Matic",
  [NetworkEnum.GNOSIS_MAINNET]: "xDAI",
  [NetworkEnum.GNOSIS_TESTNET]: "xDAI",
  [NetworkEnum.CHILIZ_MAINNET]: "Chiliz",
  [NetworkEnum.CHILIZ_TESTNET]: "Chiliz",
  [NetworkEnum.BASE_MAINNET]: "Base",
  [NetworkEnum.BASE_SEPOLIA]: "Base"
}
