import { CreatedCommunity } from 'interfaces/communityInterface'
import { createContext } from 'react'

interface SelectedCommunityContextI {
  selectedCommunity: string
  setSelectedCommunity: (string: CreatedCommunity) => void
}

const SelectedCommunityContext = createContext({} as SelectedCommunityContextI)

export default SelectedCommunityContext