import { useState, useEffect } from 'react'
import SelectedCommunityContext from './selectedCommunityContext'

export const SelectedCommunityProvider = ({ children }) => {
    const [selectedCommunity, setSelectedCommunity] = useState(() => {
      const savedCommunity = localStorage.getItem('selectedCommunity')
      return savedCommunity !== undefined ? JSON.parse(savedCommunity) : null
    })
  
    useEffect(() => {
      if (selectedCommunity !== null) {
        localStorage.setItem('selectedCommunity', JSON.stringify(selectedCommunity))
      }
    }, [selectedCommunity])
  
    return (
      <SelectedCommunityContext.Provider value={{ selectedCommunity, setSelectedCommunity }}>
        {children}
      </SelectedCommunityContext.Provider>
    )
  }
  