// ** React Imports
import { Suspense, lazy, useEffect } from "react"
import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter } from "react-router-dom"

// ** Redux Imports
import { store } from "./redux/store"
import { Provider, useDispatch, useSelector } from "react-redux"

// ** ThemeColors Context

import { ThemeContext } from "./utility/context/ThemeColors"

// ** ThemeConfig
import themeConfig from "./configs/themeConfig"

// ** Toast
import { Toaster, ToastPosition } from "react-hot-toast"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** i18n
import "./configs/i18n"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"
import { ContextProvider } from "utility/hooks/useContext"
import { CookieExpirationProvider } from "utility/context/CookieExpirationContext"
import { getWhiteLabelConfig } from "redux/whiteLabelConfig"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { SelectedCommunityProvider } from "contexts/selectedCommunityContext/selectedCommunityProvider"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

const container = document.getElementById("root")
const root = createRoot(container)

const ConfigProvider = () => {
  const dispatch = useAppDispatch()

  const { whiteLabelConfig } = useAppSelector((state) => state.whiteLabelConfig)

  useEffect(() => {
    const res = dispatch(getWhiteLabelConfig())
  }, [dispatch])
  return <div></div>
}
const queryClient = new QueryClient()

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <CookieExpirationProvider>
          <ConfigProvider />
          <ContextProvider>
            <SelectedCommunityProvider>
            <Suspense fallback={<Spinner />}>
              <ThemeContext>
                <LazyApp />
                <Toaster
                  position={themeConfig.layout.toastPosition as ToastPosition}
                  toastOptions={{ className: "react-hot-toast" }}
                />
              </ThemeContext>
            </Suspense>
            </SelectedCommunityProvider>
          </ContextProvider>
        </CookieExpirationProvider>
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
