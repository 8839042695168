import { AxiosResponse } from "axios"
import { axiosBase, configFormatter } from "./axiosBase"
import { WalletBalance } from "../interfaces/walletInterface"
import { configFormater } from "./configFormatter"
import {
  CompanyEmailTemplatesEnum,
  CreatedCompany,
  IntegrationsSettings,
  TypeformSettings
} from "./../interfaces/companyInterface/index.d"

import { CompanyRolesEnum } from "interfaces/sharedEnums/roles.enum"
import { NetworkEnum } from "interfaces/sharedEnums"

type TAccessToken = string
const controller = "/companies"

interface CompanyRequests {
  accessToken: TAccessToken
  companyParams: string
  body: Partial<CreatedCompany>
  sendgridBody: CreatedCompany["sendgridSettings"]
}

interface IUpdateCompanyEmailTemplateDto {
  accessToken: TAccessToken
  companyParams: string
  emailTemplateType: CompanyEmailTemplatesEnum
  html: string
}

const getcompany = (token: string) => {
  const config = configFormater(token)
  return axiosBase.get("/companies", config)
}

const getCompanyByJWT = (token: string): Promise<AxiosResponse<{ companies: CreatedCompany[] }>> => {
  const config = configFormater(token)
  return axiosBase.get("/companies", config)
}

export const getCompanyGoTaxes = (token: string, companyId: string): Promise<AxiosResponse> => {
  const config = configFormater(token)
  return axiosBase.get(`/companies/${companyId}/goTaxes`, config)
}

const getDefaultWalletBalance = (token: string, network?: NetworkEnum): Promise<AxiosResponse<WalletBalance>> => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  const getRoute = "/users/default-wallet/balance"
  if (network) {
    return axiosBase.get(`${getRoute}?network=${network}`, config)
  }
  return axiosBase.get(getRoute, config)
}

const getWalletBalance = (
  token: string,
  walletId: string,
  network?: NetworkEnum
): Promise<AxiosResponse<WalletBalance>> => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  const getRoute = `/wallets/${walletId}/balance`
  if (network) {
    return axiosBase.get(`${getRoute}?network=${network}`, config)
  }
  return axiosBase.get(getRoute, config)
}

const updateCompanyById = (token: string, id: string, newStatus: Partial<CreatedCompany>) => {
  const config = configFormater(token)
  return axiosBase.patch(`/companies/${id}`, newStatus, config)
}

const updateCompanyBannerById = (token: string, id: string, newStatus: CreatedCompany) => {
  const config = configFormater(token)
  return axiosBase.patch(`/companies/${id}`, newStatus, config)
}

const getIntegrations = (token: string) => {
  const config = configFormater(token)
  return axiosBase.get("/integrations", config)
}

const patchIntegration = (token: string, id: string, body: IntegrationsSettings) => {
  const config = configFormater(token)
  return axiosBase.patch(`/integrations/company/${id}`, body, config)
}

const updateCompanySendgridSettings = async ({
  companyParams,
  accessToken,
  sendgridBody
}: Pick<CompanyRequests, "accessToken" | "sendgridBody" | "companyParams">) => {
  const config = configFormatter(accessToken)
  const response: AxiosResponse = await axiosBase.patch(
    `${controller}/${companyParams}/sendgrid-settings`,
    sendgridBody,
    config
  )
  return response
}

const updateCompanyBlockListSettings = async ({ companyParams, accessToken, blockListBody }) => {
  const config = configFormatter(accessToken)
  const response: AxiosResponse = await axiosBase.patch(
    `${controller}/${companyParams}/blocklist-wallets`,
    blockListBody,
    config
  )
  return response
}

const updateCompanyTypeformSettings = async (
  companyId: string,
  accessToken: string,
  typeformBody: TypeformSettings
) => {
  const config = configFormatter(accessToken)
  const response: AxiosResponse = await axiosBase.post(
    `integrations/company/${companyId}/typeform`,
    typeformBody,
    config
  )
  return response
}

const getBalance = (token: string, id: string) => {
  const config = configFormater(token)
  return axiosBase.get(`/store/${id}/myway-balances`, config)
}

const getCompanyUsersByRole = (token: string, companyId: string, role: CompanyRolesEnum) => {
  const config = configFormater(token)
  return axiosBase.get(`/companies/${companyId}/users-roles/role/${role}`, config)
}

const removeAssociatedValidatorFromCompany = (token: string, companyId: string, userId: string) => {
  const config = configFormater(token)
  return axiosBase.delete(`/companies/${companyId}/users-roles/${userId}`, config)
}

export const getCompanyWallets = (token: string, companyId: string): Promise<AxiosResponse> => {
  const config = configFormater(token)
  return axiosBase.get(`/companies/${companyId}/wallets`, config)
}

export const getCompanyCollectionValidators = (token: string, companyId: string): Promise<AxiosResponse> => {
  const config = configFormater(token)
  return axiosBase.get(`/companies/${companyId}/collection-validators`, config)
}

const getCompanyCollections = async (token: string, companyId: string) => {
  const config = configFormater(token)
  return await axiosBase.get(`/companies/${companyId}/collections/manage`, config)
}

const getCompanyClients = async (token: string, companyId: string) => {
  const config = configFormater(token)
  return await axiosBase.get(`/companies/${companyId}/members`, config)
}

const getCompanyEnabledNetworks = async (token: string, companyId: string) => {
  const config = configFormater(token)
  return await axiosBase.get(`/companies/${companyId}/enabled-networks`, config)
}

const getCompanyAssociateUsers = async (token: string, companyId: string) => {
  const config = configFormater(token)
  return await axiosBase.get(`/companies/${companyId}/users/associate`, config)
}

const updateCompanyEmailTemplate = async ({
  accessToken,
  companyParams,
  emailTemplateType,
  html
}: IUpdateCompanyEmailTemplateDto) => {
  const config = configFormater(accessToken)
  return await axiosBase.patch(`${controller}/${companyParams}/email-template/${emailTemplateType}`, { html }, config)
}

export {
  getcompany,
  updateCompanyById,
  patchIntegration,
  getIntegrations,
  getBalance,
  getCompanyByJWT,
  updateCompanyBannerById,
  updateCompanySendgridSettings,
  getCompanyUsersByRole,
  removeAssociatedValidatorFromCompany,
  getDefaultWalletBalance,
  getWalletBalance,
  getCompanyCollections,
  getCompanyClients,
  getCompanyEnabledNetworks,
  updateCompanyBlockListSettings,
  getCompanyAssociateUsers,
  updateCompanyEmailTemplate,
  updateCompanyTypeformSettings
}
