import { UpdateWhiteLabelType, WhiteLabelInterface } from "interfaces/whitelabelInterface"
import { axiosBase } from "./axiosBase"

export const getMyWhiteLabelConfig = (id: string) => {
  return axiosBase.get(`/white-label/community/${id}`)
}

export async function getMyWhiteLabelConfigByCompany(id: string): Promise<WhiteLabelInterface> {
  const { data } = await axiosBase.get(`/white-label/company/${id}`)

  return data
}

export const getCommunityWhiteLabel = (communityId: string) => {
  return axiosBase.get(`/white-label/community/${communityId}`)
}

export const updateWhiteLabel = (token: string, communityId: string, data: UpdateWhiteLabelType) => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axiosBase.patch(`/white-label/community/${communityId}`, data, config)
}

export const updateWhiteLabelByCompany = (token: string, companyId: string, data: UpdateWhiteLabelType) => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axiosBase.patch(`/white-label/company/${companyId}`, data, config)
}

export const postCommunityWhiteLabel = (token: string, communityId: string) => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axiosBase.post(`/white-label/community/${communityId}`, {}, config)
}
