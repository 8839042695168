export enum NetworkEnum {
  ETHEREUM_MAINNET = "ethereum_mainnet",
  ETHEREUM_GOERLI = "ethereum_goerli",
  POLYGON_MAINNET = "polygon_mainnet",
  POLYGON_MUMBAI = "polygon_mumbai",
  POLYGON_AMOY = "polygon_amoy",
  CELO_ALFAJORES = "celo_alfajores",
  GNOSIS_MAINNET = "gnosis_mainnet",
  GNOSIS_TESTNET = "gnosis_testnet",
  CHILIZ_MAINNET = "chiliz_mainnet",
  CHILIZ_TESTNET = "chiliz_testnet",
  BASE_MAINNET = "base_mainnet",
  BASE_SEPOLIA = "base_sepolia"
}
