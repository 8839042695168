import { axiosBase } from "./axiosBase"

export enum UpdateTaskTypeEnum {
  BY_USE_CASE = "by_use_case",
  GENERIC = "generic",
  QUEUE_USE_CASES = "queueUseCases"
}

export enum QueueUseCasesEnum {
  LOYALTIES = "loyalties",
  ADVANCED_SETTINGS = "advancedSettings"
}

export enum TaskConfigEnum {
  LOYALTIES = "loyalties",
  EVENTS = "events",
  ENVIRONMENTAL = "environmental",
  BENEFIT_CONFIG = "benefitConfig",
  WHITELABEL_CONFIG = "whiteLabelConfig",
  TOKEN_GATING_CONFIG = "tokenGatingConfig",
  PAYMENT_CONFIG = "paymentConfig",
  AUTH_CONFIG = "authConfig"
}

interface UpdateTaskProps {
  token: string
  updateTaskDto: any
  companyId: string
  stepType: UpdateTaskTypeEnum
  step: TaskConfigEnum
  queueUseCase?: QueueUseCasesEnum
}

export const getAdminWelcomeData = ({ token, companyId }) => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axiosBase.get(`admin-welcome-data/company/${companyId}`, config)
}

export const updateTask = ({ token, updateTaskDto, companyId, stepType, step, queueUseCase }: UpdateTaskProps) => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axiosBase.patch(
    `admin-welcome-data/company/${companyId}/step-type/${stepType}/step/${step}?queueUseCase=${queueUseCase}`,
    updateTaskDto,
    config
  )
}

export const addAdminWelcomeDataQueueUseCase = ({ token, companyId, queueUseCase, taskConfig }) => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axiosBase.post(`admin-welcome-data/company/${companyId}/use-case`, { queueUseCase, taskConfig }, config)
}

export const getExploreUseCases = ({ token, companyId }) => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axiosBase.get(`admin-welcome-data/company/${companyId}/explore`, config)
}
