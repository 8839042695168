import { NetworkEnum } from "interfaces/sharedEnums"

import maticLogo from "assets/images/logo/matic-icon.png"
import xdaiLogo from "assets/images/logo/xdai-icon.png"
import chilizLogo from "assets/images/logo/chiliz-icon.png"
import baseLogo from "assets/images/logo/base-icon.png"

export const currencyLogoByNetwork = {
  [NetworkEnum.POLYGON_MAINNET]: maticLogo,
  [NetworkEnum.POLYGON_AMOY]: maticLogo,
  [NetworkEnum.POLYGON_MUMBAI]: maticLogo,
  [NetworkEnum.GNOSIS_MAINNET]: xdaiLogo,
  [NetworkEnum.GNOSIS_TESTNET]: xdaiLogo,
  [NetworkEnum.CHILIZ_MAINNET]: chilizLogo,
  [NetworkEnum.CHILIZ_TESTNET]: chilizLogo,
  [NetworkEnum.BASE_MAINNET]: baseLogo,
  [NetworkEnum.BASE_SEPOLIA]: baseLogo
}
